#lastpagermessage {
  white-space: break-spaces;
  height: 50px;
  line-height: normal;
}

.stats-footer {
  position: fixed;
  bottom: 100px;
}

.card-appliance .card-body {
  padding: 10px 15px 0px 15px !important;
  height: 77px;
}
.card-appliance .display-6 {
  margin-bottom: 0px;
  font-size: 24px;
}
.card-appliance .card-text {
  font-size: 24px;
}