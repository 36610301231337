
.pincode-input-container {
  margin: 20px 0 0;
  text-align: center;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
.pincode-input-container input {
  background: #eee !important;
  border-radius: 15px;
  width: 70px !important;
  height: 70px !important;
  color: #333 !important;
  border: 2px solid #e61f31 !important;
  margin: 0px 5px !important;
  font-size: 40px;
  font-weight: 500;
}

.hg-theme-default {
  background-color: #fff;
  width: 100%;
  margin: 15px auto 0;
}

.hg-theme-default .hg-button {
  background: #3a4756;
  color: #fff;
  font-size: 30px;
}

.hg-theme-default .hg-row .hg-button:not(:last-child) {
  margin-right: 10px;
}
.hg-theme-default .hg-row .hg-button {
  margin-bottom: 10px;
}
#todaysDate {
  margin-bottom: 10px;
}
.hg-theme-default.hg-layout-numeric .hg-button {
  height: 85px;
  box-shadow: 2px 2px 5px #1e252e;
  border: none;
}

#pin-input-area {
  width: 385px;
  font-size: 40px;
  height: 80px;
  margin: 0 auto;
  display: block;
  background-color: #3a4756;
  border: none;
  color: #fff;
  padding: 20px 60px;
  letter-spacing: 50px;
  box-shadow: 0px 0px 15px #1e252e;
}

@media (width <= 400px) {
  .pincode-input-container input {
    width: 60px !important;
    height: 60px !important;
  }
}