.App {
  text-align: center;
}

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

[data-theme="dark"] body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.home-container {
  background-color: #fff;
  color: #333333;
  margin: 5px;
  border-radius: 5px;
  min-height: 650px;
}

.white-text h2 {
  font-weight: 800 !important;
  color: #fff;
  text-align: center;
}